.full-carousel-item {
    height: 80vh; /* Full viewport height */
    object-fit: cover; /* Ensures that the image/video fits without distortion */
  }

  .no-padding {
    padding-left: 0 !important;
    padding-right: 0 !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  
  @media (max-width: 768px) {
    .full-carousel-item {
      height: 60vh; /* Adjusted height for mobile screens */
    }
  }
  
  .carousel-caption-full {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.5); /* Tinted background */
    padding: 0; /* Remove any padding */
  }
  
  .carousel-caption-full h1, 
  .carousel-caption-full p {
    text-align: center;
  }
  
  .carousel-caption-full .btn {
    display: block;
    margin: 0 auto; /* Center the button */
  }
  