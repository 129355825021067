#request-header {
    background: linear-gradient(to right, rgba(150, 148, 148, 0.214), rgba(151, 145, 145, 0.236)), url('../Images/prayerrequest.jpeg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

#request-header h1{
    font-weight: 900;
}


#request .info p{
    line-height: 30px;
}

#request .quotes{
    background-color:#800080;
    padding: 100px 10px 50px 10px;
    color: white;
}

#request button {
    background-color: #800080;
    color: white;
    height: 50px;
    width: 200px;
    text-align: center;
    margin-bottom: 20px;
    border-radius: 20px;
    font-size: 20px;
}
#request button:hover {
    background-color: #db90db;
    color: darkblue;
    height: 50px;
    width: 200px;
    text-align: center;
    margin-bottom: 20px;
    border-radius: 20px;
}

.contact-block {
    position: relative;
    background-color: #d3d3d5;
  }
.contact-block h2{
    font-weight: 700;
}
  .contact-block .row {
    margin: 0 0 25px;
  }
  
  .contact-block button {
    width: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    font-size: 20px;
    border-radius: 20px;
  }
  
  .form-control {
    margin: 10px;
    padding: 10px;
    text-align: left;
    vertical-align: top;
    border-color: #800080;
    border-width: 3px;
  }


  .contact-form .text-area {
    height: 200px;
    text-align: left !important; 
    vertical-align: top !important;
  }
@media (max-width: 480px){
    #request-header h1{
        font-weight: 200;
    } 
    #request .info p{
        line-height: 23px;
    }
    #request .quotes{
        padding: 20px;
        text-align: justify !important;
    }
}