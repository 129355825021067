footer{
	width: 100%;
	position: relative;
	bottom: 0;
	background: linear-gradient(to left, #00093c, #2d0b00);
	color: #fff;
	padding: 40px 0 30px;
	font-size: 13px !important;
	line-height: 20px;
}

footer iframe {
    border: 0;
    width: 100%;
    height: 420px;
  }

footer .row{
	width: 90%;
	margin: auto;
	display: flex;
	flex-wrap: wrap;
	align-items: flex-start;
	justify-content: space-between;
}

.col{
	flex-basis: 25%;
	padding: 10px;
}

.col:nth-child(2), .col:nth-child(3){
	flex-basis: 15%;
}


.col h3{
	width: fit-content;
	margin-bottom: 40px !important;
	position: relative !important;
  font-size: 15px !important;
}
.col p{
  font-size: 15px !important;
  font-weight: 400 !important;
}
.help-line{
	padding-top: 20px;
}
.help-line p{
	padding-top: -30px;
}

ul li{
	list-style: none;
	margin-bottom: 12px;
	margin-left: -30px;
}

ul li a{
	text-decoration: none;
	color: #fff;
}
.font-titleFont{
    font-size: 20px;
}


hr{
	width: 90%;
	border: 0;
	border-bottom: 1px solid #ccc;
	margin: 20px auto;
}

.copyright{
	text-align: center;
}

.pb-5{
	text-align: center;
}


.underline{
	width: 100%;
	height:5px;
	background: #fff;
	border-radius: 3px;
	position: absolute;
	top: 30px;
	left: 0;
	overflow: hidden;
}

.underline span{
	width: 15px;
	height: 100%;
	background: #800080;
	border-radius: 3px;
	position: absolute;
	top: 0;
	left: 10px;
	animation: moving 2s linear infinite;
}

.col ul li a:hover{
	color: #ffffff;
	padding-left: 8px;
	transition: all 0.s ease;
}
@keyframes moving{
	0%{
		left: -20px;
	}
	100%{
		left: 100%;
	}
}

@media  (max-width: 480px) {
    .hide{
        display: none;
    }
    footer{
        width: 100%;
        position: abosolute;
        bottom: 0;
        background: linear-gradient(to right, #00093c, #2d0b00);
        padding: 40px 0 30px;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        font-size: 13px;
        line-height: 20px;
    }
    .col h3{
        width: fit-content;
        margin-bottom: 40px !important;
        position: relative !important;
      font-size: 10px !important;
    }
    .col p, .copyright, .creation{
      font-size: 10px !important;
      font-weight: 400 !important;
    }
  }