#testimonies-header {
    background: linear-gradient(to right, rgba(252, 248, 248, 0), rgba(245, 242, 242, 0.301)), url('../Images/testimonies.webp');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

#testimonies-header h1{
    font-weight: 900;
   text-shadow: 2px 2px 20px purple;
}
  #testimonies-header h3{
    font-weight: 700;
   text-shadow: 2px 3px 30px black;
  }

 .main-card {
    background: linear-gradient(to right, rgb(190, 182, 190), rgba(255, 255, 255, 0.856)), url('../Images/testimonies.webp');
    border: 2px solid black;
  }

  .message-text {
    font-weight: 600;
  }