#faq .header {
    background: linear-gradient(to right, rgba(71, 59, 73, 0.586), rgba(71, 59, 73, 0.5868)), url('../Images/faq.webp');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  #faq .header h1 {
    font-weight: 900;
  }
  #faq .header p{
    font-weight: 700;
  }