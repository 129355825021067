#about {
    background: linear-gradient(290deg, #f3f3f6, #f3f3f6, #f3f3f6, rgb(222, 222, 230));
    background-size: 400% 400%;
    animation: aboutgradient 20s ease infinite;
  }

  #about .header {
    background: linear-gradient(to right, rgba(255, 255, 255, 0.779), rgba(255, 255, 255, 0.708)), url('../Images/logo.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  #about .header h1 {
    font-weight: 900;
  }
  #about .header p{
    font-weight: 700;
  }
  
  @keyframes aboutgradient {
    0% {background-position: 0% 50%;}
    25% {background-position: 100% 50%;}
    50% {background-position: 200% 50%;}
    75% {background-position: 300% 50%;}
    100% {background-position: 0% 50%;}
  }

  #about h1{
    font-size: 40px;
    font-weight: 600;
    color: #00008B;
  }

 

  #about h3{
    font-size: 30px;
    font-weight: 900;
  }

  #about p{
    font-size: 20px;
    font-weight: 900;
    font-style: italic;
  }

  @media (max-width: 480px) {

#about h3, 
#about p{
 font-size: 15px;
}
#about h1{
    font-size: 30px;
} 
#about .hide{
    display: none;
}
#about h1 {
    font-size: 20px; /* or any size you prefer */
  }
}